import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Paper, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginLeft: 10,
    marginRight: 10
  },
  paper: {
    minWidth: 400,
    minHeight: 120,
    maxWidth: 600,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

export default function AboutCard(props) {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.title} color="textSecondary" gutterBottom>
           About
      </Typography>
      <br/>
      <Grid container spacing={3}>
      <img src="./peternorthwood.png"
          width="180"
          height="140"/>
        <Grid item xs={8} lg={8}>
          <Typography variant="body2" component="p">
            Peter is an experencied Auctioneer, Lease negotiator and Asset Manager. There are few circumstances that he has not been involved with. From complex lease negotiations dealing with tenants, to asset strategy advice and rental review strategies.
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
