import React from 'react';
import { Route, Link, Switch, BrowserRouter as Router } from 'react-router-dom'
import Home from './components/Home'
import About from './components/About'
import Services from './components/Services';
import Contact from './components/Contact';


function App() {

  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/services" component={Services} />
          <Route path="/contact" component={Contact} />
        </Switch>
      </Router>
    </div>
  )
}

export default App;