import React from "react";
import Slider from "react-slick";

export default function ImageSlider(props) {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        adaptiveHeight: false
    };
    return (
        <div>
            <br/>
            <Slider {...settings} style={{maxWidth: 600}}>
                <div align='center'>
                    <img src="./pn-banner.png"/>
                </div>
                <div align='center'>
                    <img src="./buildingsc-min.png"/>
                </div>
                <div align='center'>
                    <img src="./beachpan-min.png"/>
                </div>
            </Slider>
        </div>
    );
}