import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import theme from '../../theme';
import { Link, withRouter } from 'react-router-dom'

const classes = {
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
};

class MainNavigation extends React.Component {
  constructor(props){
    super(props);
    
  }
  onSubmit = (route) => {
    this.props.history.push(`/${route}`)
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
            <AppBar position="static" style={{backgroundColor:'#F5F5F5'}}>
            <Toolbar>
                <img src="./pn-logo.png" height="50px" />
                <Button color="inherit" onClick={e => this.onSubmit('')}>Home</Button>
                <Button color="inherit" onClick={e => this.onSubmit('about')}>About</Button>
                <Button color="inherit" onClick={e => this.onSubmit('services')}>Services</Button>
                <Button color="inherit" onClick={e => this.onSubmit('contact')}>Contact</Button>
            </Toolbar>
            </AppBar>
        </div>
      </ThemeProvider>
    );
  }
}
export default (withRouter(MainNavigation))