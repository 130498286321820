import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Paper } from "@material-ui/core";
import MainNavigation from "../MainNavigation";
import { Timeline, TimelineEvent } from "react-event-timeline";

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: 200,
    minHeight: 200,
    maxWidth: 600,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2)
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
}));

const careers = [
  {
    label: "2006-Present",
    header: "Major Projects Victoria. (State Government agency)",
    body: `Pinnacle Property Group: Consulting to Asset Management Clients. (Later Charter Keck Cramer)
            Department of Human Services, Consulting to the Social Housing Sector. Housing Co-operatives and Housing Associations.
            University of Melbourne – Sessional Lecturer`
  },
  {
    label: "2001 to 2006",
    header: "Investa Property Group",
    body: `Officer in Effective Control, Investa Asset Management Pty Ltd
          Senior Asset Manager, General Manager 120 Collins Street.`
  },
  {
    label: "Oct 1988 to Mar 2001",
    header: "Colliers Jardine (Vic) Pty Ltd",
    body: `General Manager, Casselden Place Lonsdale Street Melbourne.
            Director - Property Management - Eastern Office`
  },
  {
    label: "1996 to Oct 1998",
    header: "DTZ Debenham International (Vic) Pty Ltd",
    body: `Director Property Management  (DTZ merged with Colliers, October 1988)`
  },
  {
    label: "1994 to 1996",
    header: "Bayley Stuart Pty Ltd",
    body: `Director - Proprietor`
  },
  {
    label: "1988 to 1994",
    header: "Geo. M. Hume Pty Ltd",
    body: `Director
          “Officer in effective control” responsible to the Estate Agents Board for the conduct of the business and the Trust account on a day to day basis.`
  }
];

export default function About(props) {
  const classes = useStyles();

  return (
    <div>
      <MainNavigation />
      <Paper className={classes.paper}>
        <Typography
          className={classes.header}
          color="textSecondary"
          gutterBottom
        >
          Profile
        </Typography>
        <Typography variant="body2" component="p">
          Highly experienced Property Executive with a strong commercial
          property background, managing multi and single site portfolios.
        </Typography>
        <Typography variant="body2" component="p">
          Success achieved by using highly developed competencies in negotiating
          complex leases and developing and maintaining excellent communication
          and management skills.
        </Typography>
        <br />
        <Typography
          className={classes.header}
          color="textSecondary"
          gutterBottom
        >
          Skills
        </Typography>
        <Typography variant="body2" component="p">
          • Ability to interpret and negotiate complex lease arrangements,
          cognisant of implications of various lease clauses.
        </Typography>
        <Typography variant="body2" component="p">
          • Strong capacity to build tenant relationships to ensure Landlord and
          tenant needs are met with a focus on customer service.
        </Typography>
        <Typography variant="body2" component="p">
          • Extensive experience in sales & purchase of property for clients.
        </Typography>
        <Typography variant="body2" component="p">
          • Skilled Auctioneer.
        </Typography>
        <Typography variant="body2" component="p">
          • Experience with managing diverse teams of property professionals.
        </Typography>
        • Strong implementation skills for maintenance and capital programs.
        <Typography variant="body2" component="p">
          • Highly developed property analysis skills both in development
          potential and valuation.
        </Typography>
        <br />
        <Typography
          className={classes.header}
          color="textSecondary"
          gutterBottom
        >
          Achievements
        </Typography>
        <Typography variant="body2" component="p">
          • Represented a major restaurant chain and a health fund attending to
          leasehold and freehold property requirements maintaining consistency
          of advice.
        </Typography>
        <Typography variant="body2" component="p">
          • Acted for prominent publisher in relocating its Australian
          headquarters saving cost and time by providing a single point of
          contact for the client and developers.
        </Typography>
        <Typography variant="body2" component="p">
          • Managed refurbishment projects successfully by delivering on time
          and preventing potential budget blowouts caused by variations.
        </Typography>
        <Typography variant="body2" component="p">
          • Negotiated rental reviews with majority of tenants of a single
          commercial property achieving 100% increases in all instances.
        </Typography>
        <Typography variant="body2" component="p">
          • Asset managed a high profile premium grade building at cost levels
          well within industry benchmarks.
        </Typography>
        <Typography variant="body2" component="p">
          • Advising private clients owning Commercial and Industrial property
          portfolios • Bought and sold proerty at Auction for individual
          clients.
        </Typography>
        <br />
        <Typography
          className={classes.header}
          color="textSecondary"
          gutterBottom
        >
          Qualifications
        </Typography>
        <Typography variant="body2" component="p">
          • Fellow of the Australian Property Institute
        </Typography>
        <Typography variant="body2" component="p">
          • Fellow of the Real Estate Institute of Australia
        </Typography>
        <Typography variant="body2" component="p">
          • Certified Practising Valuer (CPV)
        </Typography>
        <Typography variant="body2" component="p">
          • REIV Sworn Valuer
        </Typography>
        <Typography variant="body2" component="p">
          • Licensed Estate Agent, Auctioneer
        </Typography>
        <Typography variant="body2" component="p">
          • Accredited Real Estate Institute of Victoria Trainer
        </Typography>
        <Typography variant="body2" component="p">
          • Certificate IV Assessment & Workplace Training. (BSZ 40198)
        </Typography>
        <Typography variant="body2" component="p">
          • Honorary Fellow, Faculty of Architecture Building & Planning,
          Melbourne University.
        </Typography>
        <br />
        <Typography
          className={classes.header}
          color="textSecondary"
          gutterBottom
        >
          Industry Training & Teaching
        </Typography>
        <Typography variant="body2" component="p">
          In 1991, completed a train the trainer course with the Real Estate
          Institute of Victoria and since then involved in providing training
          and addressing property seminars in AUSTRALIA, MALAYSIA AND INDONESIA
          on behalf of a number of organizations in property management, asset
          management, sales and negotiation techniques. Melbourne University
          Lecturer (Sessional) in Property Management, Honorary Fellow - Faculty
          of Architecture Building and Planning.
        </Typography>
        <br />
        <Typography
          className={classes.header}
          color="textSecondary"
          gutterBottom
        >
          Career History
        </Typography>
        <Timeline>
          {careers.map(m => (
            <TimelineEvent key={m.header} title={m.header} createdAt={m.label}>
              {m.body}
            </TimelineEvent>
          ))}
        </Timeline>
      </Paper>
    </div>
  );
}
