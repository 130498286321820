import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Paper } from "@material-ui/core";
import MainNavigation from "../MainNavigation";
import { Timeline, TimelineEvent } from "react-event-timeline";

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: 200,
    minHeight: 200,
    maxWidth: 600,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2)
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
}));

export default function Services(props) {
  const classes = useStyles();

  return (
    <div>
      <MainNavigation />
      <Paper className={classes.paper}>
        <Typography
          className={classes.header}
          color="textSecondary"
          gutterBottom
        >
          Property Management
        </Typography>
          <Typography variant="body2" component="p">•	Commercial leasing advice, analysing leasing proposals</Typography>
          <Typography variant="body2" component="p">•	Negotiating lease deals, rental reviews</Typography>
          <Typography variant="body2" component="p">•	Tenant liaison  on behalf of Clients</Typography>
          <Typography variant="body2" component="p">•	Managing contractors, arranging maintenance contracts</Typography>
          <Typography variant="body2" component="p">•	Overseeing repairs & maintenance</Typography>
          <Typography variant="body2" component="p">•	Project manage refurbishments</Typography>
          <Typography variant="body2" component="p">•	Reviewing outgoings, preparing budgets.</Typography>
          <Typography variant="body2" component="p">•	Reviewing Statutory Revaluations, objecting to rates valuations.</Typography>
          <Typography variant="body2" component="p">•	Manage the Managing Agent, advise, attend meetings with client</Typography>
        <br />
        <Typography
          className={classes.header}
          color="textSecondary"
          gutterBottom
        >
          Asset Management
        </Typography>
          <Typography variant="body2" component="p">•	Reviewing property portfolios, suitability of asset class within portfolio</Typography>
          <Typography variant="body2" component="p">•	Buy / sell recommendations</Typography>
          <Typography variant="body2" component="p">•	Negotiate purchases on clients behalf</Typography>
          <Typography variant="body2" component="p">•	Arrange revaluations of portfolios, negotiate values with external Valuers.</Typography>
          <Typography variant="body2" component="p">•	Review Insurance values and level of cover.</Typography>
          <Typography variant="body2" component="p">•	Negotiate payment by lessees of obligations under terms of leases</Typography>
          <Typography variant="body2" component="p">•	Chase arrears of rental</Typography>
        <br />
        <Typography
          className={classes.header}
          color="textSecondary"
          gutterBottom
        >
          Auctions / Sales
        </Typography>
          <Typography variant="body2" component="p">•	Attend auctions with Clients, bidding on clients behalf</Typography>
          <Typography variant="body2" component="p">•	Fee Auctioneer</Typography>
          <Typography variant="body2" component="p">•	Available for consultation on proposed sale and leasing campaigns.</Typography>
          <Typography variant="body2" component="p">•	Sourcing property for both purchase and lease. </Typography>
      </Paper>
    </div>
  );
}
