import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Paper, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginLeft: 10,
    marginRight: 10
  },
  paper: {
    minWidth: 400,
    minHeight: 120,
    maxWidth: 600,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

export default function ContactCard(props) {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.title} color="textSecondary" gutterBottom>
           Contact
      </Typography>
      <br/>
      <Grid container spacing={3}>
      <img src="./phone.png"
          width="80"
          height="80"/>
        <Grid item xs={10} lg={10}>
          <Typography variant="body2" component="p">
            To learn more of what Peter has to offer, call 0413 087 990 or email peter@northwoodproperty.com.au
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
