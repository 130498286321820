import React from 'react';
import MainNavigation from '../MainNavigation';
import ImageSlider from '../ImageSlider';
import ServicesCard from '../ServicesCard';
import ContactCard from '../ContactCard';
import AboutCard from '../AboutCard';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: 275,
    minHeight: 200,
    maxWidth: 400,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
    marginLeft: 10,
    marginRight: 10
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function Home(props) {
  const classes = useStyles();

  return (
    <div>
      <MainNavigation/>
        <ImageSlider/>
        <br/>
        <div className={classes.root}>
          <AboutCard/>
          <ServicesCard/>
          <ContactCard/>    
        </div>
    </div>
  );
}

export default Home