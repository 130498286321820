import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Paper } from "@material-ui/core";
import MainNavigation from "../MainNavigation";
import { Timeline, TimelineEvent } from "react-event-timeline";
import ContactCard from "../ContactCard";

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: 200,
    minHeight: 200,
    maxWidth: 600,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2)
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
}));

export default function Contact(props) {
  const classes = useStyles();

  return (
    <div>
      <MainNavigation />
      <ContactCard />
    </div>
  );
}
